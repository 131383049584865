import { useState, useCallback } from "react";

/**
 * A base 1 step manager
 * @param initial step to start at
 * @param total greatest steps
 * @param firstStep the first, or lowest value, number step in steps. defaults to 1
 */
export const useSteps = (
    initial: number,
    total: number,
    firstStep?: number
) => {
    if (initial < firstStep || initial > total) {
        throw new Error("Provided initial step is not in the range of steps");
    }

    const [step, _setStep] = useState<number>(initial);

    const nextStep = useCallback(
        () => _setStep((s) => (s < total ? s + 1 : s)),
        [total]
    );

    const previousStep = useCallback(
        () => _setStep((s) => (s > firstStep ? s - 1 : s)),
        [firstStep]
    );

    const setStep = useCallback(
        (value: number) => {
            if (value < firstStep) {
                throw new Error("New step is less than first step");
            } else if (value > total) {
                throw new Error(
                    " New step is greater than total number of steps."
                );
            } else {
                _setStep(value);
            }
        },
        [firstStep, total]
    );

    return {
        step,
        nextStep,
        previousStep,
        firstStep,
        setStep,
        isFirstStep: step === firstStep,
        isLastStep: step === total,
        total
    };
};
