import React from "react";
import { useTranslation } from "react-i18next";
import { useLandingPageRedirectABTest } from "hooks/useLandingPageRedirectABTest";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers";
import HubSpotForm from "components/forms/HubSpotForm";


export const NoVideoForm: React.FC = () => {
    const { t } = useTranslation("getting-started");
    const { userInfo } = useSelector((state: RootState) => state.user);
    const { redirectToLandingPageWithABTest } = useLandingPageRedirectABTest();

    const handleContinue = () => {
        redirectToLandingPageWithABTest();
    };

    return (
        <>
            <h2>
                {t("getting-started:upload-video-step:novideotitle")}
            </h2>
            <HubSpotForm
                formId="31890725-04b6-4ac5-98fd-fcbed3563b26"
                onFormSubmitted={() => handleContinue()}
                onFormReady={(form) => {
                    form.querySelector('input[name="email"]').value =
                        userInfo?.Email;
                    form.querySelector('input[name="user_id"]').value =
                        userInfo?.UserId;
                }}
            />
        </>
    );
};
