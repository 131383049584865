import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState
} from "react";
import styles from "./index.module.scss";
import { NoVideoForm } from "./NoVideoForm";
import { VideoReady } from "./VideoReady";
import { useTranslation } from "react-i18next";
import { Button } from "components/buttons/Button";
import { useCreateUppy } from "components/video-upload/hooks/useCreateUppy";
import { v4 as uuidv4 } from "uuid";
import { IsUploadingStep } from "components/video-upload/components/IsUploadingStep";
import { useGetUploadSession } from "components/video-upload/hooks/useGetUploadSession";
import { useCreateBroadcastForUpload } from "components/video-upload/hooks/useCreateBroadcastForUpload";
import Uppy from "@uppy/core";

export const UploadVideoStep: React.FC = () => {
    const { t } = useTranslation("getting-started");
    const [internalUploadSessionId, setInternalUploadSessionId] =
        useState<string>(uuidv4());
    const [hasNoVideo, setHasNoVideo] = useState<boolean>(false);
    const [uploadStarted, setUploadStarted] = useState<boolean>(false);
    const [uploadComplete, setUploadComplete] = useState<boolean>(false);
    const uppy = useRef<Uppy>();

    const staticSessionId = useRef<string>(internalUploadSessionId);
    const { sessionHasErrors, files } = useGetUploadSession({
        sessionId: staticSessionId.current
    });
    const filesArray = useMemo(
        () => (!!files ? Object.values(files) : []),
        [files]
    );
    useCreateBroadcastForUpload({ sessionId: staticSessionId.current });

    // Reset the upload session
    const reset = useCallback(() => {
        setUploadStarted(false);
        setUploadComplete(false);
        const id = uuidv4();
        setInternalUploadSessionId(id);
        staticSessionId.current = id;
    }, []);

    // Handle errors
    useEffect(() => {
        if (sessionHasErrors) {
            reset();
        }
    }, [sessionHasErrors, reset]);

    const onUploadComplete = useCallback(() => {
        setUploadComplete(true);
    }, []);

    // Create the initial uppy instance
    const { entryPointDragAndDropInstance } = useCreateUppy({
        sessionId: internalUploadSessionId,
        setSessionId: setInternalUploadSessionId,
        allowMultipleUploads: false,
        allowAdditionalUploads: false,
        lockedToPlayer: null,
        onUploadComplete: onUploadComplete,
        location: "entry-point-component"
    });

    // Update the instance ref after a session reset
    useEffect(() => {
        uppy.current = entryPointDragAndDropInstance;
    }, [entryPointDragAndDropInstance]);

    // add the selected file to the initial uppy instance (which will create a new uppy instance for that file)
    const onFileAdded = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            uppy.current.addFile({
                data: event.target.files?.[0],
                name: event.target.files?.[0].name,
                type: event.target.files?.[0].type
            });
        },
        [uppy]
    );

    // handle adjustments to files array (either start or reset)
    useEffect(() => {
        // trigger upload for file specific uppy instance (part of our existing upload flow)
        if (
            filesArray.length > 0 &&
            filesArray.every(
                (file) =>
                    !file.isUploading &&
                    !file.isErrored &&
                    !file.isSuccess &&
                    !file.isCanceled
            )
        ) {
            setUploadStarted(true);
            filesArray.forEach((file) => file.uppyInstance.upload());
        }

        // clear upload started state if all files are errored or canceled
        if (
            filesArray.length > 0 &&
            filesArray.every((file) => file.isErrored || file.isCanceled)
        ) {
            setUploadStarted(false);
            reset();
        }
    }, [filesArray, uploadStarted, reset]);

    return (
        <div className={styles["upload-video-container"]}>
            {!hasNoVideo && !uploadStarted && !uploadComplete && (
                <div>
                    <h2>
                        {t("getting-started:upload-video-step:launch-title1")}
                        <strong>
                            {t(
                                "getting-started:upload-video-step:launch-title2"
                            )}
                        </strong>
                    </h2>

                    <p className={styles["upload-video-description"]}>
                        {t("getting-started:upload-video-step:description1")}
                        <br />
                        {t("getting-started:upload-video-step:description2")}
                    </p>

                    <div className={styles["upload-video-primary-cta"]}>
                        <label
                            className={`btn btn-primary ${styles["upload-video-button"]}`}
                            htmlFor="fileInput"
                            tabIndex={0}
                            role="button"
                        >
                            {t("getting-started:upload-video-step:link")}
                            <input
                                id="fileInput"
                                alt="input for video upload"
                                type="file"
                                name="video-upload"
                                accept="video/*"
                                onChange={onFileAdded}
                                style={{ display: "none" }}
                            />
                        </label>
                    </div>
                    <div className={styles["upload-video-secondary-cta"]}>
                        <Button
                            type={"link-primary"}
                            onClick={() => setHasNoVideo(true)}
                        >
                            {t(
                                "getting-started:upload-video-step:donthavevideo"
                            )}
                        </Button>
                    </div>
                </div>
            )}

            {uploadStarted && !uploadComplete && (
                <IsUploadingStep initialUppy={uppy} files={filesArray} />
            )}

            {hasNoVideo && <NoVideoForm />}
            {uploadComplete && <VideoReady />}
        </div>
    );
};
