import React, { useState, useMemo, useEffect, useCallback } from "react";
import styles from "./OnboardingPage.module.scss";
import { StripePriceAnonymous } from "@switcherstudio/switcher-api-client";
import { useGetStripePrice } from "hooks/useGetStripePrice";
import { NotificationContainer } from "components/notification/NotificationContainer";
import { useSteps } from "hooks/useSteps";
import Loading from "components/loading/Loading";
import { useRedirectIfDisallowed } from "hooks/useRedirectIfDisallowed";
import { useOnboardingSetupIntent } from "hooks/useOnboardingSetupIntent";
import { useCouponAnonymous } from "hooks/useCouponAnonymous";
import { OnboardingPageProps } from "./types";
import { exists } from "helpers/booleans";
import { useIsMountedRef } from "hooks/useIsMountedRef";
import { ModalManager } from "components/modal/ModalManager";
import OnboardingContent from "./OnboardingContent";
import { useHasPlanRole } from "hooks/useHasPlanRole";
import { useParams } from "hooks/useParams";

const fallbackToDefaultToSubscribe =
    import.meta.env.VITE_FALLBACK_TO_DEFAULT_TO_SUBSCRIBE === "true";

const defaultAccountCreationPlanId = import.meta.env
    .VITE_GETTING_STARTED_DEFAULT_PLAN;

const disablePlanSelectionStep = import.meta.env
    .VITE_DISABLE_PLAN_SELECTION_STEP;

const skipDownloadStep = import.meta.env.VITE_SKIP_DOWNLOAD_STEP === "true";

const isDev = import.meta.env.VITE_ENV === "dev";

export const OnboardingPage: React.FC<OnboardingPageProps> = ({
    planId,
    defaultToSubscribe,
    isTrialing,
    couponCode,
    resellerInventoryItemId,
    clientId,
    tag,
    setupIntent,
    redirectStatus,
    registerBody
}) => {
    const { debugStep } = useParams();
    const isGrowUser = useHasPlanRole("Grow");
    const [selectedPlan, setSelectedPlan] = useState<
        StripePriceAnonymous | undefined
    >();
    const isMountedRef = useIsMountedRef();

    const defaultToSubscribeFlow = useMemo(() => {
        if (defaultToSubscribe !== undefined) {
            return defaultToSubscribe;
        } else {
            return fallbackToDefaultToSubscribe;
        }
    }, [defaultToSubscribe]);

    const { plan: fetchedPlan } = useGetStripePrice(
        planId ?? defaultAccountCreationPlanId,
        "Invalid stripe price passed from marketing site"
    );

    const couponHook = useCouponAnonymous(couponCode, selectedPlan);

    const { resellerInventoryItem } = couponHook;

    // Disable the change plan sidebar link if the user was navigated here with a given plan or coupon.
    const hasPlanOrCoupon = useMemo<boolean>(() => {
        return (
            exists(planId) ||
            exists(
                couponCode && resellerInventoryItem?.ResellerInventory?.Price
            )
        );
    }, [couponCode, planId, resellerInventoryItem?.ResellerInventory?.Price]);

    // Disable the plan selection step if the env variable is set or if the user was navigated here with a given plan or coupon.
    const disableSelectionStep = useMemo<boolean>(() => {
        return disablePlanSelectionStep || hasPlanOrCoupon;
    }, [hasPlanOrCoupon]);

    // Disable the download step for grow plans.
    const disableDownloadStep = useMemo<boolean>(() => {
        return isGrowUser || skipDownloadStep;
    }, [isGrowUser]);

    // Always 1, unless we disable the plan selection step (which only exists for DTS)
    const firstStep = useMemo(
        () => (defaultToSubscribe && disableSelectionStep ? 2 : 1),
        [defaultToSubscribe, disableSelectionStep]
    );

    const stepsHook = useSteps(
        isDev && !!debugStep ? Number(debugStep) : firstStep,
        (defaultToSubscribeFlow ? 5 : 4) - (disableDownloadStep ? 1 : 0),
        firstStep
    );
    const { step, setStep } = stepsHook;

    useRedirectIfDisallowed(
        (user) =>
            (!defaultToSubscribeFlow && !!user?.userInfo) ||
            redirectStatus === "succeeded" ||
            !user?.userInfo ||
            step === 3,
        null,
        isMountedRef.current
    );

    useEffect(() => {
        if (defaultToSubscribeFlow) {
            setSelectedPlan(fetchedPlan);
        }
    }, [defaultToSubscribeFlow, fetchedPlan, setStep]);

    useEffect(() => {
        if (resellerInventoryItem?.ResellerInventory?.Price) {
            setSelectedPlan(resellerInventoryItem.ResellerInventory.Price);
            setStep(2);
        }
    }, [resellerInventoryItem, setStep]);

    // If redirect was required perfor all necessary registration steps!
    useOnboardingSetupIntent({
        setupIntent,
        redirectStatus,
        clientId,
        plan: registerBody?.Plan,
        resellerInventoryItemId,
        isTrialing,
        body: registerBody,
        onSuccess: useCallback(() => setStep(3), [setStep])
    });

    return (
        <section className={styles["app-container"]}>
            <NotificationContainer fixed />
            <Loading />
            <ModalManager />
            <OnboardingContent
                couponHook={couponHook}
                stepsHook={stepsHook}
                tag={tag}
                defaultToSubscribeFlow={defaultToSubscribeFlow}
                setSelectedPlan={setSelectedPlan}
                selectedPlan={selectedPlan}
                isTrialing={isTrialing}
                disableChangePlan={hasPlanOrCoupon}
                clientId={clientId}
                redirectStatus={redirectStatus}
                isGrowUser={isGrowUser}
            />
        </section>
    );
};
