import React, { useState, useCallback, useEffect, useMemo } from "react";
import styles from "../index.module.scss";
import { CreateAccountInput } from "components/inputs/text-input/CreateAccountInput";
import { useTranslation } from "react-i18next";
import {
    useCreateAccountForm,
    CreateAccountFormValues
} from "./useCreateAccountForm";
import { PasswordRulesTooltip } from "components/tooltips/PasswordRulesTooltip";
import { IndustryDropdown } from "../IndustryDropdown";
import { CouponInput } from "components/inputs/text-input/CouponInput";
import { ElementCheckbox } from "components/inputs/checkbox/ElementCheckbox";
import { TermsOfServicePrompt } from "../TermsOfServicePrompt";
import {
    PartnerStackPartnership,
    StripePriceAnonymous
} from "@switcherstudio/switcher-api-client";
import { useDispatch, useSelector } from "react-redux";
import { resetNotifications } from "store/notification/slice";
import { PaymentMethodInput } from "components/inputs/text-input/PaymentMethodInput";
import { StripePaymentElementChangeEvent } from "@stripe/stripe-js";
import { RootState } from "store/reducers";
import { Banner } from "components/banners/Banner";
import { useIsMountedRef } from "hooks/useIsMountedRef";
import { client } from "api/client";
import partnerstack from "helpers/partnerstack";
import {
    RegisterAccountProps,
    MaybeWithCouponInput
} from "views/page-content/onboarding/types";
import { AppDispatch } from "store/store";
import { PhoneNumberInput } from "components/inputs/text-input/PhoneNumberInput";
import SuccessIcon from "assets/icons/success.svg?react";
import { Checkbox } from "components/inputs/checkbox/Checkbox";

export interface CreateAccountFormProps
    extends MaybeWithCouponInput,
        RegisterAccountProps {
    defaultToSubscribeFlow: boolean;
    redirectStatus: string;
    isTrialing?: boolean;
    selectedPlan?: StripePriceAnonymous;
    nextStep: () => void;
    showCouponInput?: boolean;
    phoneRequired?: boolean;
}

export const CreateAccountForm: React.FC<CreateAccountFormProps> = ({
    defaultToSubscribeFlow,
    redirectStatus,
    selectedPlan,
    clientId,
    tag,
    resellerInventoryItem,
    handleCouponCode,
    removeCoupon,
    isTrialing = true,
    nextStep,
    showCouponInput = true
}) => {
    const { t } = useTranslation("getting-started");
    const dispatch = useDispatch<AppDispatch>();
    const { userInfo } = useSelector((state: RootState) => state.user);

    const isLoggedIn = useMemo<boolean>(() => !!userInfo, [userInfo]);

    const isMounted = useIsMountedRef();
    const [partnerstackPartner, setPartnerStackPartner] =
        useState<PartnerStackPartnership>(null);

    useEffect(() => {
        async function run() {
            try {
                const partnerstackId = partnerstack.getCurrentPartnerKey();
                if (!partnerstackId) return;
                const partnerstackResult =
                    await client.partnerStack_GetPartnership(partnerstackId);
                setPartnerStackPartner(partnerstackResult);
            } catch {}
        }

        run();
    }, [isMounted]);

    const showPartnerBanner = useMemo(() => {
        return (
            partnerstackPartner &&
            partnerstackPartner.Group?.Slug === "solutionpartners"
        );
    }, [partnerstackPartner]);

    const [displayTooltip, setDisplayTooltip] = useState<boolean>(false);
    const [termsChecked, setTermsChecked] = useState<boolean>(false);

    const [paymentMethodReady, setPaymentMethodReady] =
        useState<boolean>(false);

    const onPaymentElementChange = useCallback(
        async (e: StripePaymentElementChangeEvent) => {
            if (e.complete) {
                setPaymentMethodReady(true);
            } else {
                setPaymentMethodReady(false);
            }
        },
        []
    );

    const overrideValues = useMemo<CreateAccountFormValues>(() => {
        return {
            firstName: userInfo?.FirstName,
            lastName: userInfo?.LastName,
            email: userInfo?.Email
        } as CreateAccountFormValues;
    }, [userInfo]);

    const {
        values,
        errors,
        handleSubmit,
        handleChange,
        passwordValidMap,
        isSubmitting
    } = useCreateAccountForm({
        clientId,
        resellerInventoryItem,
        tag,
        plan: selectedPlan,
        overrideValues,
        isTrialing,
        redirectStatus,
        onSuccess: () => {
            dispatch(resetNotifications());
            nextStep();
        }
    });

    return (
        <form onSubmit={handleSubmit} className={styles["login-form"]}>
            {showPartnerBanner && (
                <Banner
                    header={`${t("create-account:referred-by")} ${
                        partnerstackPartner.Team?.Name
                    }`}
                    subheader={t("create-account:referral-banner-subheader")}
                    icon={<SuccessIcon />}
                />
            )}

            <span className={styles["double-line"]}>
                <CreateAccountInput
                    label={t("create-account:first-name")}
                    type="firstName"
                    id="firstName"
                    value={isLoggedIn ? userInfo.FirstName : values.firstName}
                    error={errors.firstName}
                    onChange={handleChange}
                    readOnly={isLoggedIn}
                />
                <CreateAccountInput
                    label={t("create-account:last-name")}
                    type="lastName"
                    id="lastName"
                    value={isLoggedIn ? userInfo.LastName : values.lastName}
                    error={errors.lastName}
                    onChange={handleChange}
                    readOnly={isLoggedIn}
                />
            </span>
            <CreateAccountInput
                label={t("create-account:email-address")}
                type="email"
                id="email"
                value={isLoggedIn ? userInfo.Email : values.email}
                error={errors.email}
                onChange={handleChange}
                readOnly={isLoggedIn}
            />
            {!isLoggedIn && (
                <>
                    <CreateAccountInput
                        label={t("create-account:create-password")}
                        type="password"
                        id="password"
                        value={values.password}
                        error={errors.password}
                        onChange={handleChange}
                        onFocus={() => setDisplayTooltip(true)}
                        onBlur={() => setDisplayTooltip(false)}
                    />
                    {errors.api && (
                        <div className="alert alert-danger" role="alert">
                            {errors.api}
                        </div>
                    )}
                    <PasswordRulesTooltip
                        display={displayTooltip}
                        passwordValidMap={passwordValidMap}
                    />

                    <IndustryDropdown
                        values={values}
                        errors={errors}
                        handleChange={handleChange}
                    />

                    <PhoneNumberInput
                        id="phoneNumber"
                        label={t("create-account:required")}
                        placeholder={t("create-account:phone-number")}
                        value={values.phoneNumber}
                        error={errors.phoneNumber}
                        onChange={handleChange}
                        location="create-account"
                    />
                </>
            )}

            <br />
            {defaultToSubscribeFlow && (
                <>
                    <h4 className={`${styles["title-light"]}`}>
                        {t("payment-method.title1")}{" "}
                        <strong>{t("payment-method.title2")}</strong>
                    </h4>
                    <PaymentMethodInput onChange={onPaymentElementChange} />
                </>
            )}

            {showCouponInput && (
                <CouponInput
                    label={t("create-account:add-coupon")}
                    couponCode={resellerInventoryItem?.CouponCode}
                    addCoupon={handleCouponCode}
                    removeCoupon={removeCoupon}
                />
            )}

            <ElementCheckbox
                label={<TermsOfServicePrompt />}
                id="terms-of-service-check"
                checked={termsChecked}
                onChange={() => setTermsChecked((v) => !v)}
                customClass={styles["tos-checkbox"]}
                customLabelClass={styles["checkbox-label"]}
            />

            <Checkbox
                checked={values.emailOptIn}
                id="emailOptIn"
                label={t("create-account:emails-opt-in")}
                onChange={handleChange}
                customClass={styles["me-checkbox"]}
                customLabelClass={styles["checkbox-label"]}
            />

            <button
                type="submit"
                className={`btn btn-primary mt-4 ${styles["create-account-button"]}`}
                disabled={
                    !termsChecked ||
                    (defaultToSubscribeFlow && !paymentMethodReady) ||
                    isSubmitting
                }
            >
                {t("buttons:create-account").toUpperCase()}
            </button>
        </form>
    );
};
