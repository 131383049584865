import React from "react";
import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";
import HubSpotForm from "components/forms/HubSpotForm";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers";

export interface LaunchSurveyStepProps {
    nextStep: () => void;
}

export const LaunchSurveyStep: React.FC<LaunchSurveyStepProps> = ({ nextStep }) => {
    const { t } = useTranslation("getting-started");
    const { userInfo } = useSelector((state: RootState) => state.user);

    return (
        <div className={styles["survey-container"]}>
            <h2>
                {t("getting-started:survey-step:launch-title1")}
                <strong>
                    {t("getting-started:survey-step:launch-title2")}
                </strong>
            </h2>

            <HubSpotForm
                formId="65ba3400-0214-4a82-925b-f04faaa7c13e"
                onFormSubmitted={() => nextStep()}
                onFormReady={(form) => {
                    form.querySelector('input[name="email"]').value =
                        userInfo?.Email;
                    form.querySelector('input[name="user_id"]').value =
                        userInfo?.UserId;
                }}
            />
        </div>
    );
};
