import React, { useMemo } from "react";
import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";
import { LinkList, LinkListItem } from "components/link-list";
import PlayerAppearanceIcon from "assets/icons/brandkit.svg?react";
import GatedIcon from "assets/icons/gated.svg?react";
import EyeIcon from "assets/icons/eye.svg?react";


export const VideoReady: React.FC = () => {
    const { t } = useTranslation("getting-started");

    const LinkListAction = useMemo(() => {
        return [
            {
                Title: t("getting-started:upload-video-step:ready-link1title"),
                Description: t("getting-started:upload-video-step:ready-link1desc"),
                Icon: <EyeIcon />,
                NavigateTo: "/catalog"
            },
            {
                Title: t("getting-started:upload-video-step:ready-link2title"),
                Description: t("getting-started:upload-video-step:ready-link2desc"),
                Icon: <PlayerAppearanceIcon />,
                NavigateTo: "/catalog"
            },
            {
                Title: t("getting-started:upload-video-step:ready-link3title"),
                Description: t("getting-started:upload-video-step:ready-link3desc"),
                Icon: <GatedIcon />,
                NavigateTo: "/catalog/catalog-subscription"
            }
        ] as LinkListItem[];
    }, [t]);

    return (
        <>
            <h2>
                {t("getting-started:upload-video-step:ready-title1")}
                <strong>
                    {t("getting-started:upload-video-step:ready-title2")}
                </strong>
            </h2>

            <p className={styles["upload-video-description"]}>
                {t("getting-started:upload-video-step:ready-description1")}<br />
                {t("getting-started:upload-video-step:ready-description2")}
            </p>

            <LinkList actions={LinkListAction} />
        </>
    );
};
