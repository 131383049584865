import React from "react";
import { SelectPlanSidebarStepContent } from "./sidebarStepContent/SelectPlanSidebarStepContent";
import styles from "../OnboardingPage.module.scss";
import { CreateAccountSidebarStepContent } from "./sidebarStepContent/CreateAccountSidebarStepContent";
import {
    ResellerInventoryItem,
    StripePriceAnonymous
} from "@switcherstudio/switcher-api-client";
import { DownloadSidebarStepContent } from "./sidebarStepContent/DownloadSidebarStepContent";
import { SurveySidebarStepContent } from "./sidebarStepContent/SurveySidebarStepContent";

import { TrialCreateAccountSidebarStepContent } from "./sidebarStepContent/TrialCreateAccountSidebarStepContent";

export interface SidebarContentProps {
    step: number;
    defaultToSubscribeFlow: boolean;
    plan: StripePriceAnonymous;
    resellerInventoryItem: ResellerInventoryItem;
    isTrialing: boolean | undefined;
    changePlan: () => void;
    disableChangePlan: boolean;
    isGrowUser: boolean | undefined;
}

export const SidebarContent: React.FC<SidebarContentProps> = ({
    step,
    defaultToSubscribeFlow,
    plan,
    resellerInventoryItem,
    isTrialing,
    changePlan,
    disableChangePlan,
    isGrowUser
}) => (
    <div className={styles["sidebar-content"]}>
        {defaultToSubscribeFlow ? (
            <>
                {step === 1 && <SelectPlanSidebarStepContent />}
                {step === 2 && (
                    <CreateAccountSidebarStepContent
                        plan={plan}
                        changePlan={changePlan}
                        resellerInventoryItem={resellerInventoryItem}
                        isTrialing={isTrialing}
                        disableChangePlan={disableChangePlan}
                    />
                )}
                {(step === 3 || (step === 4 && !isGrowUser)) && (
                    <SurveySidebarStepContent isTrialing={isTrialing} />
                )}
                {step === 5 && (
                    <DownloadSidebarStepContent isTrialing={isTrialing} />
                )}
            </>
        ) : (
            <>
                {step === 1 && <TrialCreateAccountSidebarStepContent />}
                {step === 2 && <DownloadSidebarStepContent />}
            </>
        )}
    </div>
);
